






















































































































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import { Message } from "element-ui";
import {ExportExcel, base64DownloadFile} from '../../utils/exportFile'

@Component({})
export default class AUser extends Vue {
  active = 0;
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  tableData2: any = [];
  // 加载状态
  loading = false;
  btnloading = false;
  // 总条数
  total = 0;
  dialog = false;
  // 搜索条件
  search: any = {
    channelId: "",
    bidMonth: "",
    tradingCenterCode: "",
    sendingOrganizationCode: "",
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30102", this.search)
      .then((data) => {
        this.active = 0
        this.total = data.total;
        data.list.forEach((item: any) => {
          item.bidMonth = this.search.bidMonth;
        });
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  //成本
  hesuanDialog = false
  hesuanInfo: any = {}
  hsRadio = '1'
  cost = ''
  toChengben(item: any) {
    this.hesuanInfo = item
    this.hesuanDialog = true
    console.log(item);
  }
  add() {
    let obj = {
      cost: this.hsRadio == '1' ? this.cost : '-' + this.cost,
      bidMonth: this.hesuanInfo.bidMonth,
      orderId: this.hesuanInfo.order_id,
      sendingOrganizationCode: this.hesuanInfo.sending_organization_code,
      tradingCenterCode: this.hesuanInfo.trading_center_code,
      channelId: this.hesuanInfo.channel_id
    }
    api.post('J30402', obj).then(res => {
      this.$message.success('操作成功')
      this.cost = ''
      this.hesuanDialog = false
      this.searchTable()
    })
  }
  cancle() {
    this.cost = ''
    this.hsRadio = '1'
    this.hesuanDialog = false
  }
  onInput0(e: any) {
    // 验证是否是纯数字
    let isNumber = /^[0-9]+.?[0-9]*$/.test(e.target.value);
    // 过滤非数字
    e.target.value = e.target.value.replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    if (!isNumber || e.target.value < 0) {
      this.$message.warning("只能输入大于0的数字");
    }
    // e.target.value = (e.target.value >= 0 && e.target.value.match(/^\d*/g)[0]) || null;
  }

  javaConfig: any = {}
  getConf() {
    api.getConf().then((res) => {
      this.javaConfig = res
    })
  }

  // 修改比例
  blDialog = false
  width = '450px'
  settlementRatio = ''
  radio = 1
  tableData3 = []
  blTotal = 0
  blSearch = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 1000, //每页数量
    }
  }
  cancleBl() {
    this.searchTable()
    this.blDialog = false
  }
  editbl() {
    this.radio = 1
    this.width = '450px'
    this.settlementRatio = ''
    this.tableData3 = []
    this.blTotal = 0
    this.blDialog = true
  }
  blLoading = false
  sureBl() {
    let obj = {
      channelId: this.search.channelId,
      bidMonth: this.search.bidMonth,
      tradingCenterCode: this.search.tradingCenterCode,
      sendingOrganizationCode: this.search.sendingOrganizationCode,
      settlementRatio: this.settlementRatio
    }
    this.blLoading = true
    api.post('J30406', obj).then(res =>{
      this.width = '700px'
      this.tableData3 = res.list
      this.blTotal = res.total
      this.$message.success('操作成功')
      this.radio = 2
    }).finally(() => {
      this.blLoading = false
    })
  }

  // 导出
  exportV() {
    let obj = {
      channelId: this.search.channelId,
      bidMonth: this.search.bidMonth,
      tradingCenterCode: this.search.tradingCenterCode,
      sendingOrganizationCode: this.search.sendingOrganizationCode
    }
    api.post('J30500', obj).then(res => {
      let name = "核算列表【" + new Date().toLocaleString() + "】.xlsx"
      base64DownloadFile(name, res )
    })
  }

  // 核算
  checkObj: any = {
    cover: true,
  };
  itemObj: any = {};
  check(item: any) {
    this.itemObj = item;
    this.checkObj = {
      ...this.checkObj,
      channelId: item.channel_id,
      accountingAmount: item.accounting_amount,
      orderId: item.order_id,
    };
    this.checkObj.accountingAmount = "";
    this.dialog = true;
  }
  checkPremiumAmount() {
    if (this.checkObj.accountingAmount == "") {
      Message.warning("核算金额不能为空");
      return;
    }
    if (
      !(
        Number(this.checkObj.accountingAmount >= 0) &&
        Number(this.checkObj.accountingAmount) <=
          Number(this.itemObj.premium_amount)
      )
    ) {
      Message.warning("核算金额在0-保费金额的区间");
      return;
    }
    api.post("J30103", this.checkObj).then((res) => {
      this.searchTable();
      this.dialog = false;
      Message.success("操作成功");
    });
  }

  //下一步
  checkAccountingAmount() {
    this.tableData2 = [];
    this.loading = true;
    api
      .post("J30104", this.search)
      .then((data) => {
        this.active = 1;
        this.tableData2.push(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // 核算
  submitAccountingAmount() {
    let obj = {
      totalAccountingAmount: this.tableData2[0].totalaccountingAmount,
      bidMonth: this.tableData2[0].bidMonth,
      channelId: this.tableData2[0].channelId,
      channelName: this.tableData2[0].channelName,
      totalLettersNumber: this.tableData2[0].totalLettersNumber,
      totalPremiumAmount: this.tableData2[0].totalPremiumAmount,
      tradingCenterCode: this.tableData2[0].tradingCenterCode,
      sendingOrganizationCode: this.tableData2[0].sendingOrganizationCode,
      tradingCenter: this.tableData2[0].tradingCenter,
      cover: true,
    };
    api.post("J30105", obj).then((res) => {
      store.dispatch("tags/delView", this.$route);
      this.$router.push({
        path: "/business/list",
      });
      Message.success("核算成功");
    });
  }

  channelName: any = "";
  activated() {
    let { channelId, tradingCenterCode, bidMonth, channelName, sendingOrganizationCode } =
      this.$route.query;
    this.search.channelId = channelId;
    this.search.tradingCenterCode = tradingCenterCode;
    this.search.sendingOrganizationCode = sendingOrganizationCode;
    this.search.bidMonth = bidMonth;
    this.channelName = channelName;
    this.searchTable();
    this.getConf()
  }
}
